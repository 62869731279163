import { VideoTypesMHD } from 'src/microservices/client/mhd/types/VideoTypesMHD';
import { api } from '../api';
import { EventsTypesMHD } from 'src/microservices/client/mhd/types/EventsTypesMHD';

export const monitorApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getMonitorVideos: builder.query<VideoTypesMHD[], void>({
            query: () => ({
                url: 'video/monitor/videos',
                method: 'GET',
            }),
        }),
        getMonitorEvents: builder.query<EventsTypesMHD[], {id: string, from: number, to: number}>({
            query: ({ id, from, to }) => ({
                url: `video/monitor/events?id=${id}&from=${from}&to=${to}`,
                method: 'GET',
            }),
        }),
        addMonitorVideo: builder.mutation<any, any>({
            query: (urls) => ({
                url: 'video/monitor/videos',
                method: 'POST',
                body: JSON.stringify(urls),
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),
        deleteMonitorVideo: builder.query<any, any>({
            query: (id) => ({
                url: `video/monitor/videos?vid=${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetMonitorVideosQuery,
    useLazyGetMonitorEventsQuery,
    useAddMonitorVideoMutation,
    useLazyDeleteMonitorVideoQuery,
} = monitorApi;

export const {
    endpoints: {},
} = monitorApi;
