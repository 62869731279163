import { createBrowserRouter } from 'react-router-dom';
// --- components
import {
    Account,
    Channels,
    HomePage,
    LayoutComponent,
    RealTime,
    Settings,
    SingleChannel,
    SingleVideoStatistic,
    Competitors,
    VideoFlow,
    Playlist,
    UserManagement,
    MHD,
} from './lazyPath.ts';
import Login from './microservices/auth/login';
// --- other
import { Paths } from './paths.ts';

export const router = createBrowserRouter([
    {
        path: Paths.login.path,
        element: <Login />,
    },
    {
        path: Paths.home.path,
        element: <LayoutComponent />,
        children: [
            {
                path: Paths.home.path,
                element: <HomePage />,
            },
            {
                path: Paths.channels.path,
                element: <Channels />,
            },
            {
                path: `${Paths.singleChannel.path}/:id`,
                element: <SingleChannel />,
            },
            {
                path: `${Paths.singleVideo.path}/:id/:videoId`,
                element: <SingleVideoStatistic />,
            },
            {
                path: Paths.account.path,
                element: <Account />,
            },
            {
                path: Paths.realtime.path,
                element: <RealTime />,
            },
            {
                path: Paths.settings.path,
                element: <Settings />,
            },
            {
                path: Paths.competitors.path,
                element: <Competitors />,
            },
            {
                path: Paths.videoflow.path,
                element: <VideoFlow />,
            },
            {
                path: `${Paths.playlist.path}/:playlistId`,
                element: <Playlist />,
            },
            {
                path: Paths.mhd.path,
                element: <MHD />,
            },

            // administration

            {
                path: Paths.userManagement.path,
                element: <UserManagement />,
            },
        ],
    },
]);
