import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
    annotationPreview: boolean,
    annotationTitle: boolean,
}

const initialState: InitialState = {
    annotationPreview: true,
    annotationTitle: true,
};

export const changeAnnotationsSlice = createSlice({
    name: 'labeled',
    initialState,
    reducers: {
        changeAnnotationPreview: (state, action: PayloadAction<boolean>) => {
            state.annotationPreview = action.payload;
        },
        changeAnnotationTitle: (state, action: PayloadAction<boolean>) => {
            state.annotationTitle = action.payload;
        },
    },
});

export const {
    changeAnnotationPreview,
    changeAnnotationTitle
} = changeAnnotationsSlice.actions;

export const selectAnnotations = (state: RootState) => state.annotations;

export default changeAnnotationsSlice.reducer;